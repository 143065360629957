import { HttpEvent, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Observable, filter, switchMap, take } from 'rxjs';

export function authInterceptor(req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> {
  switch (true) {
    case req.url.startsWith('/api/site-configuration'):
    case req.url.startsWith('/api/auth/idp-tenant'):
    case req.url.startsWith('/api/site-configuration/default-language'):
      return next(req);
  }

  const afAuth = inject(AngularFireAuth);

  if (req.url.indexOf('/assets') === -1) {
    return afAuth.idToken.pipe(
      filter((token) => !!token),
      take(1),
      switchMap((res) => next(tokenHandler(req, res))),
    );
  }

  return next(req);
}

function tokenHandler(request: HttpRequest<any>, currentUser: string | null): HttpRequest<any> {
  return request.clone({ setHeaders: { Authorization: 'Bearer ' + currentUser } });
}
