import { HttpClient } from '@angular/common/http';
import { APP_INITIALIZER } from '@angular/core';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { FIREBASE_OPTIONS } from '@angular/fire/compat';
import { SETTINGS, TENANT_ID } from '@angular/fire/compat/auth';
import { environment } from '@env/environment';
import { catchError, map, of, tap } from 'rxjs';

const provideTenantId = () => {
  let tenantId: string;
  return [
    {
      provide: APP_INITIALIZER,
      useFactory: (http: HttpClient) => {
        return () =>
          http.get<{ tenantId: string }>('/api/auth/idp-tenant').pipe(
            map(({ tenantId }) => tenantId),
            catchError((err) => {
              console.error(err);
              return of('');
            }),
            tap((id) => {
              tenantId = id;
            }),
          );
      },
      multi: true,
      deps: [HttpClient],
    },
    {
      provide: TENANT_ID,
      useFactory: () => {
        if (!tenantId) {
          console.error('Default language is not set, APP_INITIALIZER did not run');
        }
        return tenantId;
      },
      deps: [APP_INITIALIZER],
    },
  ];
};

export const firebaseProviders = [
  provideFirebaseApp(() => initializeApp(environment.firebase)),
  provideAuth(() => getAuth()),
  provideTenantId(),
  {
    provide: SETTINGS,
    useValue: { appVerificationDisabledForTesting: true },
  },
  { provide: FIREBASE_OPTIONS, useValue: environment.firebase },
];
