import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@app/modals/auth/auth.service';
import { map, Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PayGuard  {
  constructor(private router: Router, private auth: AuthService) {}

  canActivate(): Observable<boolean> {
    const currentRoute = this.router.url;
    if (currentRoute !== '/payment') {
      this.router.navigate(['/payment']);
      return of(false);
    }
    return this.auth.isLoggedIn$.pipe(
      map((isLoggedIn) => {
        return isLoggedIn;
      }),
    );
  }
}
