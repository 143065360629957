import { inject } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { map } from 'rxjs';
import { useBreakpoints } from '../../services/screen-size.service';

export const canActivateMobile =
  (redirectTo: string[]): CanActivateFn =>
  (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    const breakpoints = useBreakpoints();
    const router = inject(Router);

    return toObservable(breakpoints.md).pipe(map((md) => (md ? router.createUrlTree(redirectTo) : true)));
  };
