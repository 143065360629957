import { HttpClient } from '@angular/common/http';
import { APP_INITIALIZER } from '@angular/core';
import {
  DEFAULT_LANGUAGE,
  FakeMissingTranslationHandler,
  MissingTranslationHandler,
  TranslateCompiler,
  TranslateDefaultParser,
  TranslateFakeCompiler,
  TranslateFakeLoader,
  TranslateLoader,
  TranslateModuleConfig,
  TranslateParser,
  TranslateService,
  TranslateStore,
  USE_DEFAULT_LANG,
  USE_EXTEND,
  USE_STORE,
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { catchError, map, of, tap } from 'rxjs';

function provideTranslate(config: TranslateModuleConfig = {}) {
  return [
    config.loader || { provide: TranslateLoader, useClass: TranslateFakeLoader },
    config.compiler || { provide: TranslateCompiler, useClass: TranslateFakeCompiler },
    config.parser || { provide: TranslateParser, useClass: TranslateDefaultParser },
    config.missingTranslationHandler || { provide: MissingTranslationHandler, useClass: FakeMissingTranslationHandler },
    TranslateStore,
    { provide: USE_STORE, useValue: config.isolate },
    { provide: USE_DEFAULT_LANG, useValue: config.useDefaultLang },
    { provide: USE_EXTEND, useValue: config.extend },
    TranslateService,
  ];
}

export const translateProvider = () => {
  let defaultLanguage: string;
  return [
    {
      provide: APP_INITIALIZER,
      useFactory: (http: HttpClient) => {
        return () =>
          http.get<{ defaultLanguage: string }>('/api/site-configuration/default-language').pipe(
            map(({ defaultLanguage }) => defaultLanguage),
            catchError((err) => {
              console.error(err);
              return of('he');
            }),
            tap((lang) => {
              defaultLanguage = lang;
            }),
          );
      },
      multi: true,
      deps: [HttpClient],
    },
    {
      provide: DEFAULT_LANGUAGE,
      useFactory: () => {
        if (!defaultLanguage) {
          console.error('Default language is not set, APP_INITIALIZER did not run');
        }
        return defaultLanguage;
      },
    },
    provideTranslate({
      loader: {
        provide: TranslateLoader,
        useFactory: (http: HttpClient) => new TranslateHttpLoader(http),
        deps: [HttpClient],
      },
    }),
  ];
};
