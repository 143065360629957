import { HttpEvent, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

export function tenantInterceptor(req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> {
  if (req.url.indexOf('/assets') === -1 && environment.tenant) {
    return next(req.clone({ setHeaders: { Tenant: environment.tenant } }));
  }

  return next(req);
}
