import { inject } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { ICONS } from '@constants';

export function registerIcons() {
  const iconRegistry = inject(MatIconRegistry);
  const sanitizer = inject(DomSanitizer);

  for (const iconName in ICONS) {
    iconRegistry.addSvgIcon(iconName, sanitizer.bypassSecurityTrustResourceUrl(ICONS[iconName as keyof typeof ICONS]));
  }
}
