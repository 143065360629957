import { toObservable } from '@angular/core/rxjs-interop';
import { ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot } from '@angular/router';
import { useSiteConfig } from '@services/site-config.service';
import { map } from 'rxjs';

export const canActivateAddresses: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const config = useSiteConfig();

  return toObservable(config.loading).pipe(map(() => !!config()?.isOrderDeliveryPossible));
};
