import { DestroyRef, inject } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SuccessToastComponent } from '@app/components/snackbars/success-toast/success-toast.component';
import { AuthService } from '@app/modals/auth/auth.service';
import { BasketRepository } from '@app/state/basket.repository';
import { PaymentRepository } from '@app/state/payment.repository';

export function registerUpdateLogingStatus() {
  const authServce = inject(AuthService);
  const paymentRepository = inject(PaymentRepository);
  const basketRepository = inject(BasketRepository);
  const matSnackBar = inject(MatSnackBar);
  const destroyRef = inject(DestroyRef);

  const subscription = authServce.logout$.subscribe(() => {
    if (authServce.isLoggedIn() === false) {
      paymentRepository.setPaymentDetails(null);
      basketRepository.clearBasket();
      matSnackBar.openFromComponent(SuccessToastComponent, {
        data: { text: 'successfullyLoggedOut', subText: '', action: null },
        duration: 3000,
        panelClass: 'light-toast',
      });
    }
  });

  destroyRef.onDestroy(() => {
    subscription.unsubscribe();
  });
}
