import { DestroyRef, inject } from '@angular/core';
import { ActivatedRoute, Data, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { TitleService } from '@services/title.service';
import { combineLatest, filter, from } from 'rxjs';

export function registerUpdateTitle() {
  const destroyRef = inject(DestroyRef);
  const router = inject(Router);
  const activatedRoute = inject(ActivatedRoute);
  const translate = inject(TranslateService);
  const titleService = inject(TitleService);

  const getDataFromRoute = (route: ActivatedRoute): Data =>
    route.firstChild ? getDataFromRoute(route.firstChild) : route.snapshot.data;

  const subscription = combineLatest([
    router.events.pipe(filter((event) => event instanceof NavigationEnd)),
    from(translate.onLangChange),
  ]).subscribe(() => {
    const data = getDataFromRoute(activatedRoute);
    titleService.setTitle(translate.instant(`title.${data['title']}`));
  });

  destroyRef.onDestroy(() => {
    subscription.unsubscribe();
  });
}
