import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BasketRepository } from '@app/state/basket.repository';

@Injectable({
  providedIn: 'root',
})
export class PaymentGuard {
  private readonly basketRepo = inject(BasketRepository);
  private readonly router = inject(Router);

  canActivate() {
    const count = this.basketRepo.count();
    if (count > 0) {
      return true;
    } else {
      this.router.navigate(['/']);
      return false;
    }
  }
}
