import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AuthModule } from '@angular/fire/auth';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { RouterOutlet } from '@angular/router';

import { registerIcons } from '@services/icon.service';
import { registerUpdateLogingStatus } from '@services/register-update-loging-status';
import { registerUpdateTitle } from '@services/register-update-title';
import { setMetaSEO } from '@services/setMetaSEO';

@Component({
  selector: 'sup-root',
  template: `<router-outlet />`,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [RouterOutlet, AuthModule, MatSnackBarModule],
})
export class AppComponent {
  constructor() {
    registerUpdateTitle();
    setMetaSEO();
    registerIcons();
    registerUpdateLogingStatus();
  }
}
