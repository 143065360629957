import { ComponentType } from '@angular/cdk/portal';
import { MatDialogConfig } from '@angular/material/dialog';
import { Route } from '@angular/router';

export function dialogableRoute<T>(
  route: Route &
    Required<Pick<Route, 'path'>> & {
      dialog?: () => Promise<ComponentType<T>>;
      config?: MatDialogConfig<any>;
      isBottomSheet?: true | 'mobile';
    },
): Route {
  return {
    ...route,
    loadComponent: () => import('./dialog-content.component').then((m) => m.RoutableDialogComponent),
    data: {
      dialog: route.dialog,
      config: route.config,
      isBottomSheet: route.isBottomSheet,
      backUrl: route.path
        .split('/')
        .map(() => '..')
        .join('/'),
      ...route.data,
    },
    resolve: route.resolve,
  };
}
